@import "./constants/colors.scss";

@font-face {
  font-family: DejaVuSans;
  src: url("./media/DejaVuSans.ttf") format("ttf");
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

* {
  & :focus {
    outline: none;
  }
}

#root {
  height: 100%;
  width: 100%;
}

label[class*="MuiFormLabel-error"] {
  color: rgba(0, 0, 0, 0.54) !important;
}

*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.btn-error {
  color: $danger !important;
}

.btn-primary {
  background: linear-gradient(215.1deg, #26eb67 0%, #00ac9e 100%);
  box-shadow: 0px 4px 8px rgba(7, 170, 61, 0.24) !important;
  border-radius: 4px !important;
}

.img-in-table {
  margin-right: 15px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  background-repeat: no-repeat;
  background-size: cover;
}

.error-message {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

*[class*="MuiListItem-selected"] {
  background-color: initial !important;
  & * {
    color: $primary !important;
  }
}

.phone-country-input {
  height: 24px;
  svg {
    height: 24px;
    margin-right: 15px;
  }
}

.react-phone-number-input__row {
  display: flex;
  align-items: center;
  width: 100%;
}

.form {
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.save {
  color: white !important;
}

.dir-ltr {
  direction: ltr;
}

.cancel {
  color: #57595b !important;
  background-color: transparent !important;
  margin-right: 10px !important;
  box-shadow: none !important;
}

.logo-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 32px;
  min-width: 150px;
  @media (max-width: 800px) {
    margin-right: 0px;
    margin-bottom: 35px;
  }
  .no-logo {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
  }
  .logo {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .upload-btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }

  .upload-btn.hebrew i {
    margin: 0 0 0 5px;
  }
}

.auth-container {
  background: linear-gradient(0deg, #57595b, #57595b);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .auth {
    display: flex;
    box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
    border-radius: 8px;
    background-color: white;
    min-height: 602px;
    border: 1px solid #464646;
    @media (max-width: 600px) {
      flex-direction: column;
      min-height: auto;
    }
    .img {
      flex: 1;
      background-image: url("./media/auth-img.svg");
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width: 600px) {
        display: none;
      }
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .auth-form {
      flex: 1;
      box-sizing: border-box;
      padding: 50px 40px;
      max-width: 50%;
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
    .tabs {
      margin-bottom: 25px;
    }
    .auth-btn {
      color: $primary;
      font-family: Oswald;
      font-weight: 500;
      font-size: 21px;
      text-align: center;
      letter-spacing: 0.75px;
      text-transform: uppercase;
    }
  }
}

.form-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: #57595b;
}

.more-vert {
  width: 36px;
  height: 36px;
  padding: 0 !important;
}

.ReactTable {
  border-radius: 5px;
  background-color: white;
  .-sort-desc {
    box-shadow: none !important;
    &::after {
      content: "▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &::after {
      content: "▲";
      float: right;
    }
  }
  .rt-table {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
  .rt-tbody {
    .rt-tr {
      padding: 18px 32px;
      min-height: 72px;
    }
    .rt-td {
      display: flex;
      align-items: center;
      padding: 0px 5px !important;
      white-space: normal !important;
    }
    .active-row {
      background-color: white;
      border-left: 2px solid #121212;
      box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
    }
  }
  .rt-thead {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    overflow: auto;
    .rt-th {
      border: none;
      display: flex;
      align-items: center;
      padding: 0px 5px !important;
      white-space: normal !important;
    }
    .rt-tr {
      padding: 16px 32px;
    }
  }
}

.element-header {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-family: "Oswald";
  color: #000000;
  margin-bottom: 15px;
}

// utils classes

.cursor-pointer {
  cursor: pointer;
}

.color-white {
  color: white !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 606px) {
    margin: 0px;
  }

  @media (min-width: 576px) {
    max-width: 576px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    max-width: 768px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    max-width: 992px;
  }

  // Extra large devices (large desktops, 1170px and up)
  @media (min-width: 1170px) {
    max-width: 1170px;
  }
}
.danger {
  background-color: #f44336 !important;
  color: white !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}
.paper {
  padding: 32px;
}

.flex-wrap {
  flex-wrap: wrap;
}
