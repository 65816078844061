.main {
  background: #e7e7e7;
  display: flex;
  height: 100vh;
}

.children {
  height: 100%;
  overflow: auto;
  padding: 15px 30px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @media(max-width: 960px){
    padding: 15px;
  }
}

.drawer {
  flex: 1;
  width: 206px;
  div[class*="paper"] {
    z-index: 1;
  }
}
